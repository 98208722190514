'use strict';

// Packages
import 'jquery';
import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';

// Components
import { Utils } from './utils/utils';
import { Nav } from './components/nav';
import { Slider } from './components/slider';
import { MobileMenu } from './components/mobile-menu';
import { MobileLeftNav } from './components/mobile-left-nav';

function mt(n,d,s,b) {
  location.href="mailto:"+n+"@"+d+"?subject="+encodeURI(s)+"&body="+encodeURI(b);
}

const App = {
  init: function() {
    Utils.init();
    Nav.init();
    Slider.init();
    MobileMenu.init();
    MobileLeftNav.init();
  }
}

$(function() {
  App.init();
});
